import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { DropDownItem } from "../../../pojo/DropDownItem";
import { OptService } from "../../../services/opt.service";
import { jSith } from "../../../util/jquery-replacement";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-opt-search-chooser")
@Component({
    selector: "s25-ng-opt-search-chooser",
    template: `
        <div class="ngInlineBlock ngCompdropdown">
            <span class="ngHiddenSR">Searches</span>
            <s25-ng-search-dropdown
                [hasFav]="true"
                [chosen]="chosen"
                [itemTypeId]="itemTypeId"
                [allowNonQueryId]="true"
                [searchEnabled]="true"
                (chosenChange)="dropDownChange($event)"
            ></s25-ng-search-dropdown>
        </div>
    `,
    styles: `
        /* Search bar should not collide with arrows in small window size and not stretch too far on large window size */
        s25-ng-opt-search-chooser .ngCompdropdown {
            max-width: 100%;
            min-width: 33%;
            width: 300px;
        }

        s25-ng-opt-search-chooser s25-ng-search-dropdown .ngDropdownPaginated .ngSelectFavorite--text {
            text-overflow: ellipsis;
            max-width: 160px;
            overflow: hidden;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptSearchChooserComponent {
    @Input() chosen: DropDownItem;
    @Input() itemTypeId: number;
    @Input() loggedIn: boolean;
    @Input() compType: string;
    @Output() onDisableRelatedLocationsChange = new EventEmitter<boolean>();
    @Output() onChange = new EventEmitter<undefined>();
    @Output() chosenChange = new EventEmitter<any>();

    constructor(
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    // S25opt can have a search list where users pick a search from a dropdown. This fires the getData
    // method when the dropdown changes
    dropDownChange(newChosen: DropDownItem) {
        this.chosen = newChosen;

        if (!this.chosen?.itemTypeId) this.onDisableRelatedLocationsChange.emit(true);
        else this.onDisableRelatedLocationsChange.emit(parseInt(String(this.chosen.itemTypeId)) !== 104);
        if (this.loggedIn) OptService.setQueryPreference(String(this.chosen.val), this.compType);

        this.onChange.emit();
        this.chosenChange.emit(this.chosen);
        this.changeDetector.detectChanges();
    }
}
