import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-opt-group-calendar-dropdown")
@Component({
    selector: "s25-ng-opt-group-calendar-dropdown",
    template: `
        <div class="ngInlineBlock ngOptElementPad">
            <label class="c-margin-right--quarter">Group Calendar By:</label>
            <select [(ngModel)]="value" (ngModelChange)="onChange.emit($event)" class="cn-form__control ngOptDateProp">
                <option value="respond_by">Respond By Date</option>
                <option value="first_date">Occurrence Date</option>
                <!-- ANG-1641 changed to occurrence view instead of start date  -->
            </select>
        </div>
    `,
    styles: `
        .ngOptDateProp {
            top: 0.25rem;
            vertical-align: text-bottom;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptGroupCalendarDropdownComponent {
    @Input() value: boolean | string;
    @Output() onChange = new EventEmitter<boolean>();

    constructor(private elementRef: ElementRef) {
        this.elementRef.nativeElement.angBridge = this;
    }
}
