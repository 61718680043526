import { NgModule } from "@angular/core";
import { S25OptComponent } from "./s25.opt.component";
import { CommonModule } from "@angular/common";
import { S25PreferenceToggleModule } from "../s25-preference-toggle/s25.preference.toggle.module";
import { S25MultiQueryModule } from "../s25-multi-query/s25.multi.query.module";
import { FormsModule } from "@angular/forms";
import { S25RegisterButtonModule } from "../s25-register-button/s25.register.button.module";
import { S25CancelRequestModule } from "../s25-cancel-request/s25.cancel.request.module";
import { S25NumWeeksChooserModule } from "../s25-num-weeks-chooser/s25.num.weeks.chooser.module";
import { S25DayChooserModule } from "../s25-day-chooser/s25.day.chooser.module";
import { S25ColumnChooserModule } from "../s25-column-chooser/s25.column.chooser.module";
import { S25LocationAlwaysShareModule } from "../s25-location-always-share/s25.location.always.share.module";
import { S25SecurityLinkModule } from "../s25-security-link/s25.security.link.module";
import { S25HelpModule } from "../s25-help/s25.help.module";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25DatepickerModule } from "../s25-datepicker/s25.datepicker.module";
import { PopoverModule } from "../s25-popover/popover.module";
import { S25PipesModule } from "../../pipes/s25.pipes.module";
import { S25DatePatternModule } from "../s25-datepattern/s25.datepattern.module";
import { S25OptVisualizationButtonsComponent } from "./s25-opt-components/s25.opt.visualization.buttons.component";
import { S25OptAvailLegendButtonComponent } from "./s25-opt-components/s25.opt.avail.legend.button.component";
import { S25OptEditToggleButtonComponent } from "./s25-opt-components/s25.opt.edit.toggle.button.component";
import { S25OptSearchChooserComponent } from "./s25-opt-components/s25.opt.search.chooser.component";
import { S25OptMultiQueryComponent } from "./s25-opt-components/s25.opt.multi.query.component";
import { S25OptPagesComponent } from "./s25-opt-components/s25.opt.pages.component";
import { S25OptCheckboxesComponent } from "./s25-opt-components/s25.opt.checkboxes.component";
import { S25OptEditEventButtonComponent } from "./s25-opt-components/s25.opt.edit.event.button.component";
import { S25OptGroupCalendarDropdownComponent } from "./s25-opt-components/s25.opt.group.calendar.dropdown.component";
import { S25OptDateOptionsComponent } from "./s25-opt-components/s25.opt.date.options.component";
import { S25OptUtilVisualizationComponent } from "./s25-opt-components/s25.opt.util.visualization.component";
import { S25OptDateViewButtonsComponent } from "./s25-opt-components/s25.opt.date.view.buttons.component";
import { S25OptTaskTypeChooserComponent } from "./s25-opt-components/s25.opt.task.type.chooser.component";
import { S25OptModeDropdownComponent } from "./s25-opt-components/s25.opt.mode.dropdown.component";
import { S25OptRefreshButtonComponent } from "./s25-opt-components/s25.opt.refresh.button.component";
import { S25OptHelpButtonComponent } from "./s25-opt-components/s25.opt.help.button.component";
import { S25OptLastUpdateComponent } from "./s25-opt-components/S25.opt.last.update.component";
import { S25OptCreateTodoButtonComponent } from "./s25-opt-components/s25.opt.create.todo.button.component";
import { S25EventStatesModule } from "../s25-event-states/s25.event.states.module";
import { S25OfficeHoursSliderModule } from "../s25-office-hours-slider/s25.office.hours.slider.module";
import { S25ModalModule } from "../s25-modal/s25.modal.module";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25OptBulkEditButtonComponent } from "./s25-opt-components/s25.opt.bulk.edit.button.component";
import { S25BulkEditModule } from "../s25-bulk-edit/s25.bulk.edit.module";
import { S25OptAvailOptionsButtonComponent } from "./s25-opt-components/s25.opt.avail.options.button.component";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { S25AvailLegendComponent } from "../s25-avail-weekly/s25.avail.legend.component";
import { S25InlineDatepickerComponent } from "../../standalone/s25.inline.datepicker.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        S25PreferenceToggleModule,
        S25MultiQueryModule,
        S25RegisterButtonModule,
        S25CancelRequestModule,
        S25NumWeeksChooserModule,
        S25DayChooserModule,
        S25ColumnChooserModule,
        S25LocationAlwaysShareModule,
        S25SecurityLinkModule,
        S25HelpModule,
        S25DropdownPaginatedModule,
        S25CheckboxModule,
        S25DatepickerModule,
        PopoverModule,
        S25PipesModule,
        S25DatePatternModule,
        S25EventStatesModule,
        S25OfficeHoursSliderModule,
        S25ModalModule,
        S25LoadingInlineModule,
        S25BulkEditModule,
        S25ToggleButtonModule,
        S25AvailLegendComponent,
        S25InlineDatepickerComponent,
    ],
    exports: [S25OptComponent, S25OptVisualizationButtonsComponent],
    declarations: [
        S25OptComponent,
        S25OptVisualizationButtonsComponent,
        S25OptAvailLegendButtonComponent,
        S25OptEditToggleButtonComponent,
        S25OptSearchChooserComponent,
        S25OptMultiQueryComponent,
        S25OptPagesComponent,
        S25OptCheckboxesComponent,
        S25OptEditEventButtonComponent,
        S25OptGroupCalendarDropdownComponent,
        S25OptDateOptionsComponent,
        S25OptUtilVisualizationComponent,
        S25OptDateViewButtonsComponent,
        S25OptTaskTypeChooserComponent,
        S25OptModeDropdownComponent,
        S25OptRefreshButtonComponent,
        S25OptHelpButtonComponent,
        S25OptLastUpdateComponent,
        S25OptCreateTodoButtonComponent,
        S25OptBulkEditButtonComponent,
        S25OptAvailOptionsButtonComponent,
    ],
    providers: [
        S25OptComponent,
        S25OptVisualizationButtonsComponent,
        S25OptAvailLegendButtonComponent,
        S25OptEditToggleButtonComponent,
        S25OptSearchChooserComponent,
        S25OptMultiQueryComponent,
        S25OptPagesComponent,
        S25OptCheckboxesComponent,
        S25OptEditEventButtonComponent,
        S25OptGroupCalendarDropdownComponent,
        S25OptDateOptionsComponent,
        S25OptUtilVisualizationComponent,
        S25OptDateViewButtonsComponent,
        S25OptTaskTypeChooserComponent,
        S25OptModeDropdownComponent,
        S25OptRefreshButtonComponent,
        S25OptHelpButtonComponent,
        S25OptLastUpdateComponent,
        S25OptCreateTodoButtonComponent,
        S25OptBulkEditButtonComponent,
        S25OptAvailOptionsButtonComponent,
    ],
})
export class S25OptModule {}
