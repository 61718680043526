import { ChangeDetectionStrategy, Component } from "@angular/core";
import { S25ModalComponent } from "../../s25-modal/s25.modal.component";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-opt-avail-legend-button")
@Component({
    selector: "s25-ng-opt-avail-legend-button",
    template: `
        <a
            (click)="openModal(legendModal)"
            (keyup.enter)="openModal(legendModal)"
            class="aw-button aw-button--outline"
            tabindex="0"
        >
            Legend
        </a>
        <s25-ng-modal #legendModal [title]="'Availability Color Legend'" [size]="'xs'">
            <ng-template #s25ModalBody><s25-ng-avail-legend /></ng-template>
        </s25-ng-modal>
    `,
    styles: `
        :host {
            align-self: flex-end;
        }

        a {
            top: -0.1em;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptAvailLegendButtonComponent {
    openModal(modal: S25ModalComponent) {
        return modal.open();
    }
}
