import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation, ViewChild } from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { StateService } from "../../../services/state.service";
import { SearchCriteria } from "../../../pojo/SearchCriteria";
import { SearchService } from "../../../services/search/search.service";
import { SearchUtil } from "../../../services/search/s25.search.util";
import { ContactService } from "../../../services/contact.service";

@TypeManagerDecorator("s25-ng-opt-bulk-edit-button")
@Component({
    selector: "s25-ng-opt-bulk-edit-button",
    template: ` <button class="aw-button aw-button--outline" (click)="goToBulkEdit()">Bulk Edit</button> `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptBulkEditButtonComponent {
    @Input() query?: string;
    @Input() cacheId?: number;
    @Input() objectId?: number;
    @Input() searchModel?: SearchCriteria.Model;
    @Input() totalSearchRowCount?: number;
    @Input() dateView?: string = "recentHistory";

    async goToBulkEdit() {
        const userName = await ContactService.getCurrentUsername();
        let BULK_LIMIT = 1000;
        if (userName === "service25") BULK_LIMIT = 1500;

        if (!this.totalSearchRowCount || this.totalSearchRowCount < 1) {
            alert("There is no data to process yet. If a search is running, please wait for it to complete.");
            return false;
        } else if (this.totalSearchRowCount > BULK_LIMIT) {
            alert(
                "This search contains too many items. Please restrict your search to " +
                    BULK_LIMIT +
                    " or fewer items.",
            );
            return false;
        }

        if (this.searchModel) {
            let { id, name, tempIds } = await SearchUtil.createSearch(this.searchModel, {}, this.objectId);
            this.query = "&query_id=" + id;
        }

        StateService.go(
            "bulkEdit",
            {
                search: this.query,
                cacheId: this.cacheId,
                itemTypeId: this.objectId,
                dateView: this.dateView || "recentHistory",
            },
            {},
        );
    }
}
