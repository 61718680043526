import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { Item } from "../../../pojo/Item";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-opt-edit-toggle-button")
@Component({
    selector: "s25-ng-opt-edit-toggle-button",
    template: `
        <s25-ng-preference-toggle
            [prefName]="prefNames[itemTypeId]"
            (modelValueChange)="onToggle.emit($event)"
        ></s25-ng-preference-toggle>
    `,
    styles: `
        :host {
            margin-right: auto;
        }

        label {
            margin-bottom: -1px;
        }

        @media screen and (max-width: 650px) {
            :host {
                position: static;
                margin-right: 0 !important;
            }
        }

        ::ng-deep s25-ng-opt-edit-toggle-button s25-ng-preference-toggle > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 0 !important;
        }

        ::ng-deep s25-ng-opt-edit-toggle-button s25-ng-preference-toggle > div > label {
            margin: 0;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptEditToggleButtonComponent {
    @Input() itemTypeId:
        | Item.Ids.Event
        | Item.Ids.Organization
        | Item.Ids.Contact
        | Item.Ids.Location
        | Item.Ids.Resource;
    @Output() onToggle = new EventEmitter<boolean>();

    prefNames = {
        1: "EventShowInlineEdit",
        2: "AcctShowInlineEdit",
        3: "ContShowInlineEdit",
        4: "SpaceShowInlineEdit",
        6: "ResourceShowInlineEdit",
    };
}
