import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-opt-pages")
@Component({
    selector: "s25-ng-opt-pages",
    template: `
        <label>
            Page:
            <select
                aria-label="Choose a page"
                class="cn-form__control"
                [(ngModel)]="pageNumber"
                (ngModelChange)="onChange.emit($event)"
            >
                @for (i of pages; track i) {
                    <option [ngValue]="i">{{ i }}</option>
                }
            </select>
        </label>
    `,
    styles: `
        label {
            margin-bottom: 0;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptPagesComponent {
    @Input() pages: number[];
    @Input() pageNumber: number;
    @Output() onChange = new EventEmitter<number>();
}
