import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { ViewButtonValue } from "../s25.opt.component";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-opt-date-view-buttons")
@Component({
    selector: "s25-ng-opt-date-view-buttons",
    template: `
        <div class="ngInlineBlock">
            @if (hasRecentHistory) {
                <button
                    title="Past 18 months"
                    (click)="onChange.emit('recentHistory')"
                    class="btn btn-default ngCompview ngOptRecentHistory"
                >
                    Recent & Future
                </button>
            }
            @if (hasFutureOnly) {
                <button
                    title="Events Occurring today or later"
                    (click)="onChange.emit('futureOnly')"
                    class="btn btn-default ngCompview ngOptFutureOnly"
                >
                    Future
                </button>
            }
            @if (hasAllDates) {
                <button (click)="onChange.emit('allDates')" class="btn btn-default ngCompview ngOptAllDates">
                    All Dates
                </button>
            }
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptDateViewButtonsComponent {
    @Input() hasFutureOnly: boolean;
    @Input() hasRecentHistory: boolean;
    @Input() hasAllDates: boolean;
    @Output() onChange = new EventEmitter<ViewButtonValue>();
}
