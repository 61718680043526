import { ChangeDetectorRef, Component, Output, EventEmitter } from "@angular/core";
import { PreferenceService } from "../../../services/preference.service";
import { jSith } from "../../../util/jquery-replacement";
import { S25ModalComponent } from "../../s25-modal/s25.modal.component";

@Component({
    selector: "s25-ng-opt-avail-options-button",
    template: `
        <a
            (click)="openModal(availDisplayModal)"
            (keyup.enter)="openModal(availDisplayModal)"
            class="aw-button aw-button--outline"
            tabindex="0"
            >Options
        </a>
        <s25-ng-modal #availDisplayModal [title]="'Availability Options'" [size]="'xs'" (modalClosed)="close()">
            <ng-template #s25ModalBody>
                @for (pref of booleanPrefs | keyvalue; track pref) {
                    <div class="c-margin-bottom--single">
                        <div class="ngBold c-margin-bottom--quarter">{{ pref.value.label }}</div>
                        @if (pref.comments) {
                            <p class="ngFinePrint c-margin-bottom--half">
                                {{ pref.comments }}
                            </p>
                        }
                        <div id="locationDisplay">
                            <s25-toggle-button
                                id="event"
                                (modelValueChange)="updatePref(pref.key, $event)"
                                [(modelValue)]="pref.value.value"
                                [trueLabel]="pref.value.trueLabel"
                                [falseLabel]="pref.value.falseLabel"
                            ></s25-toggle-button>
                        </div>
                    </div>
                }
            </ng-template>
        </s25-ng-modal>
    `,
})
export class S25OptAvailOptionsButtonComponent {
    @Output() modalClosed = new EventEmitter();

    isChanged = false;
    booleanPrefs: { [key: string]: BooleanPrefs } = {
        availCapacity: {
            value: true,
            falseLabel: "No Capacity",
            falseValue: "none",
            trueLabel: "Default Layout Capacity",
            trueValue: "defaultLayout",
            label: "Location Capacity",
            comments: "Show the capacity of the default layout next to each location name",
        },
        availSpaceName: {
            value: true,
            trueLabel: "Formal Name",
            trueValue: "room_long",
            falseLabel: "Name",
            falseValue: "room_short",
            label: "Location Display Name",
        },
    };

    constructor(private changeDetector: ChangeDetectorRef) {}

    async getData() {
        const prefNames = Object.keys(this.booleanPrefs);
        const resp = await PreferenceService.getPreferences(prefNames);
        jSith.forEach(resp, (prefName, respItem) => {
            this.booleanPrefs[prefName].value = respItem.value === this.booleanPrefs[prefName].trueValue;
            this.booleanPrefs[prefName].initValue = this.booleanPrefs[prefName].value;
        });
    }

    updatePref(prefName: string, newValue: boolean) {
        const pref = this.booleanPrefs[prefName];
        this.isChanged = true;
        return PreferenceService.setPreference(prefName, newValue ? pref.trueValue : pref.falseValue, "U");
    }

    async openModal(modal: S25ModalComponent) {
        await this.getData();
        return modal.open();
    }

    close() {
        this.isChanged && this.modalClosed.emit();
        this.isChanged = false;
    }
}

type BooleanPrefs = {
    value: boolean;
    initValue?: boolean;
    trueLabel: string;
    trueValue: string;
    falseLabel: string;
    falseValue: string;
    label: string;
    comments?: string;
};
