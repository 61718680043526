import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { VisualizationType } from "../s25.opt.component";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-opt-visualization-buttons")
@Component({
    selector: "s25-ng-opt-visualization-buttons",
    template: `
        @if (hasList) {
            <button
                class="btn btn-default ngCompview"
                [ngClass]="{ 'btn-primary': selected === 'list' }"
                (click)="vizChange.emit('list')"
            >
                List
            </button>
        }
        @if (hasCalendar) {
            <button
                class="btn btn-default ngCompview"
                [ngClass]="{ 'btn-primary': selected === 'calendar' }"
                (click)="vizChange.emit('calendar')"
            >
                Calendar
            </button>
        }
        @if (hasAvailability) {
            <button
                class="btn btn-default ngCompview"
                [ngClass]="{ 'btn-primary': selected === 'availability' }"
                (click)="vizChange.emit('availability')"
            >
                Availability
            </button>
        }
        @if (hasAvailabilityWeekly) {
            <button
                class="btn btn-default ngCompview"
                [ngClass]="{ 'btn-primary': selected === 'availabilityWeekly' }"
                (click)="vizChange.emit('availabilityWeekly')"
            >
                Availability Weekly
            </button>
        }
    `,
    styles: `
        ::ng-deep .nm-party--on s25-ng-opt-visualization-buttons button {
            background-color: #28272c !important;
            border-color: rgba(255, 255, 255, 0.24) !important;
            color: white !important;
        }

        ::ng-deep .nm-party--on s25-ng-opt-visualization-buttons button:hover {
            color: #73b0e2 !important;
            border-color: #d7dadc !important;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptVisualizationButtonsComponent {
    @Input() hasList: boolean = false;
    @Input() hasCalendar: boolean = false;
    @Input() hasAvailability: boolean = false;
    @Input() hasAvailabilityWeekly: boolean = false;
    @Input() selected: VisualizationType;
    @Output() vizChange = new EventEmitter<VisualizationType>();
}
