export const S25OptConst = {
    subject2Prefname: {
        event: "25L_event_search_columns",
        location: "25L_space_search_columns",
        resource: "25L_resource_search_columns",
        organization: "25L_org_search_columns",
        contact: "25L_cont_search_columns",
    },
    modeOptions: [
        { label: "Separated", value: 1 },
        { label: "Overlapping", value: 0 },
    ],
    refreshEvent: "opt-refresh",
    viewButton2Class: {
        futureOnly: ".ngOptFutureOnly",
        allDates: ".ngOptAllDates",
        recentHistory: ".ngOptRecentHistory",
    },
    activeViewClass: "btn-primary",
    subject2Lang: {
        event: "s25-event_details",
        location: "s25-space_details",
        resource: "s25-resource_details",
        organization: "s25-organization_details",
        task: "s25-task_agenda",
    },
};
