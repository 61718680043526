import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { StateService } from "../../../services/state.service";
import { EventService } from "../../../services/event.service";
import { Event } from "../../../pojo/Event";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-opt-edit-event-button")
@Component({
    selector: "s25-ng-opt-edit-event-button",
    template: `
        @if (isInit && moreActionPerms?.hasEditEvent) {
            <a
                class="ngInlineBlock ngOptElementPad c-textButton"
                tabindex="0"
                (click)="editEvent()"
                (keyup.enter)="editEvent()"
            >
                <svg class="c-svgIcon c-svgIcon--medium" role="img">
                    <title>Create an Event Icon</title>
                    <use xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#edit"></use>
                </svg>
                Edit Event
            </a>
        }
    `,
    styles: `
        a {
            margin-bottom: 3px;
        }

        svg {
            vertical-align: text-bottom;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptEditEventButtonComponent implements OnInit {
    @Input() itemId: number;
    @Input() itemTypeId: number;

    moreActionPerms: Event.Permissions.CopyEditEmail;
    isInit: boolean = false;

    constructor(private changeDetector: ChangeDetectorRef) {}

    async ngOnInit() {
        if (!this.itemId || this.itemTypeId != 1) return;
        this.moreActionPerms = await EventService.formEventCopyEditEmailPermsFromId(this.itemId);
        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    async editEvent() {
        await this.updateCopyEventPerms();
        if (this.moreActionPerms.hasRelatedEvents) {
            // Todo: Switch to TS ModalService once the 'copy-edit-event' and its dependencies (primarily s25-list) have been migrated
            window.angBridge.$injector
                .get("s25ModalService")
                .modal("copy-edit-event", { objId: this.itemId, type: "edit" });
            // ModalService.modal("copy-edit-event", { objId: this.itemId, type: "edit" });
        } else {
            StateService.gotoRose({
                itemId: this.itemId,
                itemTypeId: this.itemTypeId,
            });
        }
    }

    async updateCopyEventPerms() {
        const itemData = await EventService.getMoreActionsData(this.itemId);
        this.moreActionPerms.hasRelatedEvents = itemData && !!itemData.has_related;
    }
}
