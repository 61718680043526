import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-opt-util-visualization")
@Component({
    selector: "s25-ng-opt-util-visualization",
    template: `
        <label for="availView" class="ngInline">View:</label>
        <select
            id="availView"
            class="ng-pristine ng-valid ng-not-empty ng-touched cn-form__control"
            [disabled]="!!editable"
            [(ngModel)]="utilRateViz"
            (ngModelChange)="onUtilRateVizChange.emit($event)"
        >
            @for (item of choices; track item) {
                <option [ngValue]="item">
                    {{ item.disp }}
                </option>
            }
        </select>
    `,
    styles: `
        :host {
            display: flex;
            align-items: baseline;
        }

        label {
            margin-right: 0.3125rem;
        }

        select.cn-form__control {
            line-height: 1em;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptUtilVisualizationComponent {
    @Input() editable: boolean;
    @Input() utilRateViz: { type: number };
    @Input() choices: { disp: string; type: string; itemProp: string }[];
    @Output() onUtilRateVizChange = new EventEmitter<{ type: number }>();

    constructor(private elementRef: ElementRef) {
        this.elementRef.nativeElement.angBridge = this;
    }
}
