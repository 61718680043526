import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { AvailService } from "../../../services/avail.service";
import { CalendarService } from "../../home/calendar/calendar.service";
import { EventDetailsListService } from "../../../services/event.details.list.service";
import { MergeSearchService } from "../../../services/merge.search.service";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25Const } from "../../../util/s25-const";
import { Item } from "../../../pojo/Item";

export interface QueryI {
    itemId: number;
    itemName: string;
}

@TypeManagerDecorator("s25-ng-opt-multi-query")
@Component({
    selector: "s25-ng-opt-multi-query",
    template: `
        @if (itemTypeId) {
            <s25-multi-query
                [itemTypeId]="itemTypeId"
                [selectedQueries]="selectedQueries"
                [whiteList]="whiteList"
                [noUpdate]="noUpdate"
                [dropdown]="dropdown"
                [simpleCollapse]="simpleCollapse"
                (onChange)="onAction($event)"
                (onItemChange)="onItemAction($event)"
            ></s25-multi-query>
        }
    `,
    styles: `
        div.c-objectDetails.c-objectDetails--borderedSection {
            display: inline-block;
            width: 200px;
            margin-bottom: 0;
            text-align: left;
        }

        div.c-objectDetails.c-objectDetails--borderedSection .c-sectionHead.c-sectionHead {
            padding-bottom: 0.4rem;
            padding-top: 0.4rem;
        }

        div.c-objectDetails.c-objectDetails--borderedSection .c-objectDetails--collapseLabel {
            outline-offset: 0 !important;
        }

        div.c-objectDetails.c-objectDetails--borderedSection svg {
            margin-top: -3px;
        }

        div.c-objectDetails.c-objectDetails--borderedSection div.content-wrapper {
            height: 200px;
            overflow: auto;
            width: 100%;
            background-color: #fff;
            position: absolute;
            z-index: 100;
            border: 1px solid rgba(0, 0, 0, 0.15);
        }

        div.c-objectDetails.c-objectDetails--borderedSection div.content-wrapper--hidden {
            background-color: transparent;
            border: 0;
        }

        s25-simple-collapse label {
            margin-top: 0;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptMultiQueryComponent implements OnInit {
    @Input() weekStart: number;
    @Input() compType: string;
    @Input() compSubject: Item.Subject;
    @Input() date: Date;
    @Input() endDate: any;
    @Input() showRelatedLocations: boolean;
    @Input() showRelatedEvents: boolean;
    @Input() editable: { value: boolean };
    @Input() includeRequested: boolean;
    @Input() includeAdditional: boolean;
    @Input() itemTypeId: number;
    @Output() multiQueryStrChange = new EventEmitter<string>();
    @Output() onChange = new EventEmitter<undefined>();
    @Output() hasMultiQueryChange = new EventEmitter<boolean>();

    selectedQueries: QueryI[];
    whiteList: QueryI[];
    noUpdate: false;
    dropdown: boolean;
    simpleCollapse = true;

    onAction(queries: QueryI[]) {
        this.multiQueryStrChange.emit(queries.length ? queries.map((s) => s.itemId).join("+") : null);
        this.onChange.emit();
    }

    onItemAction({ item, selected }: { item: any; selected: QueryI[] }) {
        if (this.dropdown) return this.onAction(selected);
        if (!item?.chosen) return;

        let mockScope = {
            weekstart: this.weekStart,
            comptype: this.compType,
            compsubject: this.compSubject,
            modelBean: {
                weekstart: this.weekStart,
                comptype: this.compType,
                compsubject: this.compSubject,
                searchQuery: "&query_id=" + item.itemId,
                query_id: item.itemId,
                optBean: {
                    weekstart: this.weekStart,
                    comptype: this.compType,
                    compsubject: this.compSubject,
                    date: this.date,
                    endDate: this.endDate,
                    showRelatedLocations: this.showRelatedLocations,
                    showRelatedEvents: this.showRelatedEvents,
                    editable: this.editable,
                    includeRequested: this.includeRequested,
                    includeAddtl: this.includeAdditional,
                },
            },
        };

        let compParams = null;
        let componentOverride = null;
        if (this.compType.includes("avail")) {
            compParams = AvailService.getCacheParams(mockScope);
        } else if (this.compType.includes("cal") || this.compType === "home") {
            compParams = CalendarService.getCacheParams(mockScope, null);
        } else if (this.compType === "event-details-list") {
            compParams = EventDetailsListService.getCacheParams(mockScope);
            componentOverride = 1;
        }
        if (compParams) return MergeSearchService.preCache(componentOverride, compParams);
    }

    ngOnInit() {
        const embeddedConfig = window.ProData.embeddedConfig;
        this.itemTypeId ??= S25Const.subject2ItemTypeId[this.compSubject];
        this.selectedQueries = [];
        if (embeddedConfig?.queryId)
            this.selectedQueries.push({
                itemId: embeddedConfig.queryId,
                itemName: embeddedConfig.queryName,
            });
        this.whiteList = embeddedConfig?.queryList || [];
        this.dropdown = embeddedConfig?.multiQueryDropdown;
    }
}
