import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { TelemetryService } from "../../../services/telemetry.service";
import { S25Util } from "../../../util/s25-util";

@TypeManagerDecorator("s25-ng-opt-checkboxes")
@Component({
    selector: "s25-ng-opt-checkboxes",
    template: `
        @if (!!hasBlackouts) {
            <div class="ngInlineBlock ngOptElementPad c-optionsBar__hasBlackouts">
                <s25-ng-checkbox
                    [(modelValue)]="showBlackouts"
                    (modelValueChange)="setInParent('showBlackouts', $event)"
                    [labelId]="'showBlackouts'"
                    >Show Blackouts
                </s25-ng-checkbox>
            </div>
        }
        @if (!!hasRelatedEvents) {
            <div class="ngInlineBlock ngOptElementPad c-optionsBar-related-events">
                <s25-ng-checkbox
                    [(modelValue)]="showRelatedEvents"
                    (modelValueChange)="setInParent('showRelatedEvents', $event)"
                    [labelId]="'checkRelatedEvents'"
                    >Related Events
                </s25-ng-checkbox>
            </div>
        }
        @if (!!hasIncludeRequested) {
            <div class="ngInlineBlock ngOptElementPad c-optionsBar-include-requested">
                <s25-ng-checkbox
                    [(modelValue)]="showIncludeRequested"
                    (modelValueChange)="onIncludeRequestedChange($event)"
                    [labelId]="'checkIncludeRequested'"
                    >Include Requested
                </s25-ng-checkbox>
            </div>
        }
        @if (!!hasAdditionalTime) {
            <div class="ngInlineBlock ngOptElementPad c-optionsBar-include-additional">
                <s25-ng-checkbox
                    [(modelValue)]="showIncludeAdditional"
                    (modelValueChange)="setInParent('includeAddtl', $event)"
                    [labelId]="'checkIncludeAddtl'"
                    >Display Additional Time
                </s25-ng-checkbox>
            </div>
        }
        @if (!!hasRelatedLocations) {
            <div
                class="ngInlineBlock ngOptElementPad c-text-align-medium--left c-showRelated-btn c-optionsBar-related-locations"
            >
                <s25-ng-checkbox
                    [(modelValue)]="showRelatedLocations"
                    (modelValueChange)="setInParent('showRelatedLocations', $event)"
                    [labelId]="itemId + '-relatedLoc-' + compType"
                    [disabled]="disableRelatedLocations"
                    >Related Locations
                </s25-ng-checkbox>
            </div>
        }
        @if (!!hasCombineRelatedEvents) {
            <div class="ngInlineBlock ngOptElementPad c-showRelated-btn">
                <s25-ng-checkbox
                    [(modelValue)]="showCombineRelatedEvents"
                    (modelValueChange)="setInParent('combineRelatedEvents', $event)"
                    [labelId]="'combineRelatedEvents'"
                    >Combine Related Events
                </s25-ng-checkbox>
            </div>
        }
        @if (!!hasHideNoChargeItems) {
            <div>
                <s25-ng-checkbox
                    [(modelValue)]="hideNoChargeItems"
                    (modelValueChange)="setInParent('hideNoChargeItems', $event)"
                    [labelId]="'hideNoChargeItems'"
                >
                    Hide No-Charge Items
                </s25-ng-checkbox>
            </div>
        }

        @if (!!hasHideViewerSeat) {
            <div>
                <s25-ng-checkbox
                    [(modelValue)]="hideViewerSeat"
                    (modelValueChange)="setInParent('hideViewerSeat', $event)"
                    [labelId]="'hideViewerSeat'"
                >
                    Hide Viewer Seat
                </s25-ng-checkbox>
            </div>
        }
        @if (!!hasSnapToGrid) {
            <div [hidden]="!editable" class="ngInlineBlock ngOptElementPad">
                <s25-ng-checkbox
                    [(modelValue)]="showSnapToGrid"
                    (modelValueChange)="onSnapToGridChange($event)"
                    [labelId]="'gridSnap'"
                >
                    Snap To Grid
                </s25-ng-checkbox>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptCheckboxesComponent {
    @Input() hasBlackouts: boolean;
    @Input() hasRelatedEvents: boolean;
    @Input() hasIncludeRequested: boolean;
    @Input() hasAdditionalTime: boolean;
    @Input() hasRelatedLocations: boolean;
    @Input() hasCombineRelatedEvents: boolean;
    @Input() hasHideNoChargeItems: boolean;
    @Input() hasHideViewerSeat: boolean;
    @Input() hasSnapToGrid: boolean;

    @Input() showBlackouts: boolean;
    @Input() showRelatedEvents: boolean;
    @Input() showIncludeRequested: boolean;
    @Input() showIncludeAdditional: boolean;
    @Input() showRelatedLocations: boolean;
    @Input() showCombineRelatedEvents: boolean;
    @Input() hideNoChargeItems: boolean;
    @Input() hideViewerSeat: boolean;
    @Input() showSnapToGrid: boolean;

    @Input() itemId: number;
    @Input() compType: string;
    @Input() disableRelatedLocations: boolean;
    @Input() editable: boolean;
    @Output() onChange = new EventEmitter<{ key: string; value: boolean }>();
    @Output() getData = new EventEmitter<undefined>();
    @Output() includeRequestedChange = new EventEmitter<boolean>();
    @Output() snapToGridChange = new EventEmitter<boolean>();

    setInParent(key: string, value: boolean, getData: boolean = true) {
        if (this.compType && value) {
            // send with category as, eg: Avail, Cal, etc; sub cat as: Daily, Weekly, Location, Resource, etc
            // and event type as: showBlackouts, showRelatedEvents, etc
            let adjCompType = this.compType.replace(/-/g, "_");
            adjCompType = adjCompType.replace("availability", "avail");
            adjCompType = adjCompType.replace("calendar", "cal");
            if (adjCompType === "weekly") {
                adjCompType = "avail_weekly";
            }
            let parts = adjCompType.split("_");
            let category = S25Util.firstCharToUpper(parts[0]);
            let subCategory: string;
            if (parts.length > 1) {
                subCategory = S25Util.firstCharToUpper(parts[1]);
            }
            TelemetryService.sendWithSub(category, subCategory, S25Util.firstCharToUpper(key));
        }
        this.onChange.emit({ key, value });
        if (getData) this.getData.emit();
    }

    onIncludeRequestedChange(yes: boolean) {
        this.setInParent("includeRequested", yes);
        this.includeRequestedChange.emit(yes);
    }

    onSnapToGridChange(snap: boolean) {
        this.setInParent("snapToGrid", snap);
        this.snapToGridChange.emit(snap);
    }
}
