import { Component } from "@angular/core";
import { ModalService } from "../../modal/modal.service";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-opt-create-todo-button")
@Component({
    selector: "s25-ng-opt-create-todo-button",
    template: ` <button class="btn btn-default" (click)="createTodo()">Create To Do Task</button> `,
})
export class S25OptCreateTodoButtonComponent {
    createTodo() {
        ModalService.modal("create-edit-task", { taskType: 5, type: "create" });
    }
}
