import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-opt-help-button")
@Component({
    selector: "s25-ng-opt-help-button",
    template: `
        <s25-help-link [helpTopic]="topic">
            <a class="btn btn-flat btn-icon" aria-label="Help">
                <svg class="c-svgIcon c-margin-right--quarter" role="img">
                    <title>Help</title>
                    <use
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#help"
                    ></use>
                </svg>
                <span>Help</span>
            </a>
        </s25-help-link>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptHelpButtonComponent {
    @Input() topic: string;
}
