import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-opt-task-type-chooser")
@Component({
    selector: "s25-ng-opt-task-type-chooser",
    template: `
        <label for="taskType">View:</label>
        <select
            [(ngModel)]="type"
            (ngModelChange)="onTypeChange.emit($event)"
            class="c-selectInput ng-pristine ng-valid ng-scope ng-not-empty ng-touched"
            id="taskType"
        >
            <option value="1">All Assigned Tasks</option>
            <option value="2">Only Tasks Assigned To You</option>
        </select>
    `,
    styles: `
        :host {
            display: flex;
        }

        label {
            align-self: flex-end;
            margin-right: 5px;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptTaskTypeChooserComponent {
    @Input() type: any;
    @Output() onTypeChange = new EventEmitter<any>();

    constructor(private elementRef: ElementRef) {
        elementRef.nativeElement.angBridge = this;
    }
}
