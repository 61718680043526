import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewEncapsulation } from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-opt-refresh-button")
@Component({
    selector: "s25-ng-opt-refresh-button",
    template: `
        <div class="viewOptions_Refresh ngCpointer">
            <span
                (click)="onRefresh.emit()"
                (keydown)="handleKeydownRefresh($event)"
                aria-label="Refresh"
                role="button"
                tabindex="0"
                class="btn btn-flat btn-icon"
            >
                <!-- please do not change the refreshid (optRefrsh) here, workaround for workflow refresh dataset -->
                <svg class="c-svgIcon" role="img">
                    <title>Refresh</title>
                    <use
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#refresh"
                    ></use>
                </svg>
            </span>
        </div>
    `,
    styles: `
        @media screen and (max-width: 1270px) {
            /* Add extra margin to space out refresh button nicely */
            .viewOptions_Refresh {
                margin-right: 10px;
            }
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptRefreshButtonComponent {
    @Output() onRefresh = new EventEmitter<undefined>();

    handleKeydownRefresh(e: KeyboardEvent): void {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            this.onRefresh.emit();
        }
    }
}
