import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-opt-last-update")
@Component({
    selector: "s25-ng-opt-last-update",
    template: `
        <span class="viewOptions_Datestamp">
            <span>
                <b>(as of {{ lastUpdate | dateFormat: timeFormat }})</b>
            </span>
        </span>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptLastUpdateComponent {
    @Input() lastUpdate: Date | string | number;
    @Input() timeFormat: string;
}
